<script>
import { ArrowUpIcon, ArrowRightIcon, PaperclipIcon} from "vue-feather-icons";
import Navbar from "@/components/navbar-private";
import Footer from "@/components/footer";
import Vue from 'vue'
import duppla from '@/duppla'
import moment from "moment";

/**
 * Index-developer component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowRightIcon,
    PaperclipIcon
  },
  data() {
    return {
      uuid: this.$store.state.auth.tokenPayload.uuid,
      user: {},
      attachments: {pa: [], others:[]},
      notesAudio: null,

      fileNameToDelete: '',
      folderToDelete: '',
      deletingObject: false,

      loadingMedicalRecord: false,
      resultDocument: null,
      catalogs: {
        sex: [
          {value: "female", text: "Femenino"},
          {value: "male", text: "Masculino"}
        ],
        yesNo: [
          {value: "yes", text: "Sí"},
          {value: "no", text: "No"}
        ],
        tumorType: [
          {value: "soft", text: "Blando"},
          {value: "hard", text: "Duro"}
        ],
        scales: [
          {value: '100', text:'No presenta síntomas'},
          {value: '90', text:'Síntomas leves que no afectan su vida diaria'},
          {value: '80', text:'Síntomas moderados (lleva su vida diaria a pesar de síntomas, pero requiere mayor esfuerzo)'},
          {value: '70', text:'No requiere asistencia (pediátrico: presenta restricciones y su tiempo de juego se ha reducido)'},
          {value: '60', text:'Requiere ocasionalmente asistencia, pero el paciente puede llevar sus propias actividades (pediátrico: su tiempo de juego se ve limitado y requiere supervisión/asistencia)'},
          {value: '50', text:'Requiere de considerable asistencia (pediátrico: la asistencia para juego activo es siempre necesaria)'},
          {value: '40', text:'Más del 50% del tiempo'},
          {value: '30', text:'Casi todo el tiempo en cama, frecuentemente dormido'},
          {value: '20', text:'Todo el tiempo postrado en cama y requiriendo de ayuda de cuidadores profesionales y/o familiares (pediátrico: no juega, no se levanta)'},
          {value: '10', text:'Completamente en cama y con mínima conciencia o en estado comatoso'}
        ],
        notesType: [
          {value: "written", text: "Escrito"},
          {value: "spoken", text: "Hablado (audio)"}
        ],
        menstruationAge: [
          {value: "na", text: "No ha ocurrido"},
          {value: "0-8", text: "8 años o menos"},
          {value: "9-11", text: "Entre 9 y 11 años"},
          {value: "12-15", text: "Entre 12 y 15 años"},
          {value: "16+", text: "Más de 16 años"}
        ],
        ivsaAge: [
          {value: "na", text: "No ha ocurrido"},
          {value: "0-18", text: "18 años o menos"},
          {value: "19-20", text: "Entre 19 y 20 años"},
          {value: "21+", text: "Más de 21 años"}
        ],
        relatives: [
          {value: "father", text: "Padre"},
          {value: "mother", text: "Madre"},
          {value: "brother", text: "Hermano"},
          {value: "sister", text: "Hermana"},
          {value: "uncle", text: "Tío(a)"},
          {value: "cousin", text: "Primo(a)"},
          {value: "grandparent", text: "Abuelo(a)"}
        ],
      }
    };
  },
  computed: {
    linkedLists () {
      let list = [
        "symptoms",
        "allergies",
        "surgeries",
        "medicines",
        "direct_relatives",
        "diseases"
      ]
      return list
    },
    fields () {
      let fields = ''
      fields += '?fields%5B%5D=contact_email'
      fields += '&fields%5B%5D=contact_firstname'
      fields += '&fields%5B%5D=contact_lastname'
      fields += '&fields%5B%5D=contact_phone_country'
      fields += '&fields%5B%5D=contact_phone'
      fields += '&fields%5B%5D=patient_city'
      fields += '&fields%5B%5D=contact_source'
      fields += '&fields%5B%5D=patient_social_security'
      fields += '&fields%5B%5D=user_is_patient'
      fields += '&fields%5B%5D=patient_firstname'
      fields += '&fields%5B%5D=patient_lastname'
      fields += '&fields%5B%5D=patient_country'
      fields += '&fields%5B%5D=patient_birth'
      fields += '&fields%5B%5D=patient_sex'
      fields += '&fields%5B%5D=weight'
      fields += '&fields%5B%5D=height'
      fields += '&fields%5B%5D=service_reason'
      fields += '&fields%5B%5D=current_diagnosis'
      fields += '&fields%5B%5D=diagnosis_date'
      fields += '&fields%5B%5D=tumor'
      fields += '&fields%5B%5D=tumor_palpable'
      fields += '&fields%5B%5D=tumor_visible'
      fields += '&fields%5B%5D=tumor_painful'
      fields += '&fields%5B%5D=tumor_moving'
      fields += '&fields%5B%5D=tumor_type'
      fields += '&fields%5B%5D=scale_q1'
      fields += '&fields%5B%5D=scale_q2'
      fields += '&fields%5B%5D=scale_kps'
      fields += '&fields%5B%5D=symptoms'
      fields += '&fields%5B%5D=treatment'
      fields += '&fields%5B%5D=medicine'
      fields += '&fields%5B%5D=medicines'
      fields += '&fields%5B%5D=notes'
      fields += '&fields%5B%5D=notes_type'
      fields += '&fields%5B%5D=pending_studies'
      fields += '&fields%5B%5D=pending_studies_list'
      fields += '&fields%5B%5D=allergy'
      fields += '&fields%5B%5D=allergies'
      fields += '&fields%5B%5D=transfusion'
      fields += '&fields%5B%5D=transfusion_date'
      fields += '&fields%5B%5D=transfusion_reason'
      fields += '&fields%5B%5D=surgery'
      fields += '&fields%5B%5D=surgeries'
      fields += '&fields%5B%5D=body'
      fields += '&fields%5B%5D=disease'
      fields += '&fields%5B%5D=diseases'
      fields += '&fields%5B%5D=f_menstruation_age'
      fields += '&fields%5B%5D=ivsa_age'
      fields += '&fields%5B%5D=l_menstruation_age'
      fields += '&fields%5B%5D=pregnant'
      fields += '&fields%5B%5D=previous_pregnancies'
      fields += '&fields%5B%5D=previous_pregnancies_qty'
      fields += '&fields%5B%5D=birth_qty'
      fields += '&fields%5B%5D=c_section_qty'
      fields += '&fields%5B%5D=abortion_qty'
      fields += '&fields%5B%5D=lactation_months'
      fields += '&fields%5B%5D=smoke'
      fields += '&fields%5B%5D=smoke_years'
      fields += '&fields%5B%5D=smoke_qty_day'
      fields += '&fields%5B%5D=alcohol'
      fields += '&fields%5B%5D=alcohol_frequency'
      fields += '&fields%5B%5D=alcohol_amount'
      fields += '&fields%5B%5D=alcohol_years'
      fields += '&fields%5B%5D=drugs'
      fields += '&fields%5B%5D=drugs_description'
      fields += '&fields%5B%5D=drugs_frequency'
      fields += '&fields%5B%5D=drugs_years'
      fields += '&fields%5B%5D=sleep'
      fields += '&fields%5B%5D=exercise'
      fields += '&fields%5B%5D=eating_habit'
      fields += '&fields%5B%5D=direct_relative'
      fields += '&fields%5B%5D=direct_relatives'
      fields += '&fields%5B%5D=wizard_step'
      fields += '&fields%5B%5D=form_completed_date'
      fields += '&fields%5B%5D=status'
      return fields
    },
    scale_kps_label () {
      if (this.user.scale_q1 === 'yes'){
        return '¿El paciente presenta síntomas?'
      } else if (this.user.scale_q1 === 'no' && this.user.scale_q2 === 'no'){
        return '¿El paciente requiere de asistencia para actividades diarias (adultos: aseo, alimentación, vestirse. Niños: en el juego diario)?'
      } else if (this.user.scale_q1 === 'no' && this.user.scale_q2 === 'yes'){
        return '¿A qué grado está confinado a estar postrado en cama el paciente?'
      } else {
        return ''
      }
    },
    currYear () {
      return moment().year()
    },
    currDate () {
      return moment().format('YYYY-MM-DD')
    },
  },
  methods: {
    getMedicalRecord () {
      return new Promise((resolve, reject) => {
        if(!this.loadingMedicalRecord){
          this.loadingMedicalRecord = true
          duppla({ url: 'my-medical-record' + this.fields, method: 'GET', data: null })
            .then(response => {
              if(!response.data){
                throw new Error("Expecting one record")
              }
              this.user = response.data.fields

              //Airtable linked records
              if (this.user.contact_phone_country){
                this.user.contact_phone_country = this.user.contact_phone_country[0]
              }
              if (this.user.patient_country){
                this.user.patient_country = this.user.patient_country[0]
              }
              this.getAttachments('pa')
              this.getAttachments('others')
              this.getAttachments('audio').then(response => {
                // console.log('response audios: ' + JSON.stringify(response, null, 2))
                for (var i=0; i<response.length; i++) {
                  if (response[i].Filename === 'notes.mp3') {
                    this.notesAudio = response[i].Url
                    break;
                  }
                }
              })

              resolve()
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.loadingMedicalRecord = false
            })
        }
      })
    },
    findArr (catalog, value, valueField = "value", textField = "text") {
      let arr = this.catalogs[catalog]
      if (Array.isArray(arr)) {
        let obj = arr.find(o => o[valueField] === value);
        if (obj) {
          return obj[textField]
        }
      }
      return null
    },
    yerasDiff(date) {
      return moment().diff(date, 'years')
    },
    getCountries () {
      duppla({ url: 'countries', method: 'GET' })
        .then(response => {
          this.catalogs.countries = response.data
        })
    },
    getAttachments (folder) {
      return new Promise((resolve, reject) => {
        duppla({ url: 's3/objects?folder=' + folder, method: 'GET', data: null })
          .then(response => {
            // console.log('response getAttachments: ' + JSON.stringify(response, null, 2))
            this.attachments[folder] = response.data.Contents
            resolve(this.attachments[folder])
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {})
      })
    },
  },
  created () {
    this.getCountries()
    this.getMedicalRecord().then(() => {}).finally(() => {})
  },
  mounted() {
  }
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half-170 w-100 home-wrapper overflow-hidden" style="padding-top: 70px; padding-bottom: 90px;">
      <div class="container mt-5">
        <div class="position-relative" style="z-index: 1">
          <div class="card rounded shadow-lg bg-white p-4">
            <div class="card-body">
              <div class="row justify-content-center" v-if="loadingMedicalRecord">
                <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
              </div>
              <span v-if="!loadingMedicalRecord">
                <h4 class="card-title mb-4">Información de Contacto </h4>
                <hr/>
                <div class="row">
                  <div class="col-md-4">
                    <strong>Nombre:</strong> {{user.contact_firstname}} {{user.contact_lastname}}
                  </div>
                  <div class="col-md-4">
                    <strong>Email:</strong> {{user.contact_email}}
                  </div>
                  <div class="col-md-4">
                    <strong>Teléfono:</strong> 
                    {{ user.contact_phone_country?findArr('countries', user.contact_phone_country, 'value', 'textLada'):"-" }} 
                    {{user.contact_phone}}
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4">Información del Paciente</h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    <strong>Nombre:</strong> {{user.patient_firstname}} {{user.patient_lastname}}
                  </div>
                  <div class="col-md-12">
                    <strong>Fecha de Nacimiento:</strong> {{user.patient_birth}}
                  </div>
                  <div class="col-md-12">
                    <strong>Edad:</strong> {{ yerasDiff(user.patient_birth) }} años
                  </div>
                  <div class="col-md-12">
                    <strong>Sexo de Nacimiento:</strong> {{findArr('sex', user.patient_sex)}}
                  </div>
                  <div class="col-md-4">
                    <strong>Peso:</strong> {{user.weight}} kg
                  </div>
                  <div class="col-md-4">
                    <strong>Altura:</strong> {{user.height}} cm
                  </div>
                  <div class="col-md-12">
                    <strong>Lugar de Residencia:</strong> 
                    {{ user.patient_city}}, 
                    {{ user.patient_country?findArr('countries', user.patient_country, 'value', 'textCountry'):"-" }} 
                  </div>
                  <div class="col-md-12">
                    <strong>Tipo de Seguridad Social:</strong> {{user.patient_social_security}}
                  </div>
                  <div class="col-md-12">
                    <strong>Motivo por el que solicita el servicio:</strong> 
                    {{ user.service_reason}}
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4">Patología Actual</h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    <strong>Padecimiento por el que requiere segunda opinión:</strong> {{ user.current_diagnosis}}
                  </div>
                  <div class="col-md-12">
                    <strong>Fecha de diagnóstico:</strong> {{ user.diagnosis_date}}
                  </div>
                  <div class="col-md-12">
                    <strong>¿Padece de algún tumor? </strong> {{findArr('yesNo', user.tumor)}}
                  </div>
                  <div class="col-md-12" v-if="user.tumor === 'yes'">
                    <strong>¿Su tumor le causa dolor?</strong> {{findArr('yesNo', user.tumor_painful)}}
                  </div>
                  <div class="col-md-12" v-if="user.tumor === 'yes'">
                    <strong>¿Su tumor es palpable?</strong> {{findArr('yesNo', user.tumor_palpable)}}
                  </div>
                  <div class="col-md-12" v-if="user.tumor_palpable === 'yes'">
                    <strong>¿Su tumor es visible?</strong> {{findArr('yesNo', user.tumor_visible)}}
                  </div>
                  <div class="col-md-12" v-if="user.tumor_palpable === 'yes'">
                    <strong>¿Su tumor se mueve?</strong> {{findArr('yesNo', user.tumor_moving)}}
                  </div>
                  <div class="col-md-12" v-if="user.tumor_palpable === 'yes'">
                    <strong>¿Su tumor es blando o duro?</strong> {{findArr('tumorType', user.tumor_type)}}
                  </div>
                  <div class="col-md-12 mt-4">
                    <strong>¿Escala KPS?</strong> {{user.scale_kps}}
                  </div>
                  <div class="col-md-12">
                    <strong>¿El paciente lleva su actividad de manera normal?</strong> {{findArr('yesNo', user.scale_q1)}}
                  </div>
                  <div class="col-md-12" v-if="user.scale_q1 == 'no'">
                    <strong>¿El paciente reposa en cama más de la mitad de su día (sin contar la noche)?</strong> {{findArr('yesNo', user.scale_q2)}}
                  </div>
                  <div class="col-md-12" v-if="(user.scale_q1 && user.scale_q2) || user.scale_q1 === 'yes'">
                    <strong>{{scale_kps_label}}</strong> {{findArr('scales', user.scale_kps)}}
                  </div>
                  <div class="col pt-2" v-if="user.scale_kps && user.scale_kps != '100'">
                    <div class="p-md-4" v-if="user.symptoms.length > 0">
                      <h5 class="mb-2 card-title text-muted">Sintomas</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">Descripción</th>
                              <th scope="col" class="text-center">Mes y año en que apareció</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.symptoms">
                              <td>{{item.fields.description}}</td>
                              <td class="text-center">{{formatDate(item.fields.date, 'MMM YYYY')}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Actualmente toma algún medicamento para este padecimiento concreto?</strong> {{findArr('yesNo', user.medicine)}}
                  </div>
                  <div class="col pt-2" v-if="user.medicine === 'yes'">
                    <div class="p-md-4" v-if="user.medicines.length > 0">
                      <h5 class="mb-2 card-title text-muted">Medicamentos</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col">Dosis</th>
                              <th scope="col">Frecuencia</th>
                              <th scope="col">Duración</th>
                              <th scope="col">Indicaciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.medicines">
                              <td>{{item.fields.name}}</td>
                              <td>{{item.fields.dose}}</td>
                              <td>{{item.fields.frequency}}</td>
                              <td>{{item.fields.duration}}</td>
                              <td>{{item.fields.indications}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Qué procedimiento adicional (incluyendo posibles cirugías) le han indicado para su padecimiento?</strong> {{user.treatment}}
                  </div>
                  <div class="col pt-2" v-if="attachments.pa.length > 0">
                    <div class="p-md-4">
                      <h5 class="mb-2 card-title text-muted">Archivos adjuntos relacionados a la patología actual</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Adjunto</th>
                              <th scope="col" class="text-center">Tamaño</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in attachments.pa">
                              <td><paperclip-icon class="fea icon-sm mr-2"></paperclip-icon></td>
                              <td><a :href="item.Url" target="_blank"><i class="mdi mdi-open-in-new"> </i> &nbsp;{{item.Filename}} </a></td>
                              <td class="text-center"><small class="text-dark mx-2 text-muted">{{humanFileSize(item.Size)}}</small></td>
                              <!--td>
                                <a href="#" class="" v-b-modal.DeleteObjectModal @click.prevent="fileNameToDelete = item.Filename; folderToDelete = item.Folder">
                                  <x-circle-icon class="fea icon-sm text-danger"></x-circle-icon>
                                </a>
                              </td-->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Cuenta con estudios pendientes por hacer?</strong> {{findArr('yesNo', user.pending_studies)}}
                  </div>
                  <div class="col-md-12" v-if="user.pending_studies === 'yes'">
                    <strong>¿Cuáles?</strong> {{user.pending_studies_list}}
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4">Patología Actual (en sus propias palabras)</h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    Situación actual en sus propias palabras, así como sus principales inquietudes con respecto a su padecimiento y sus expectativas en cuanto a nuestro servicio
                    <p class="text-muted"><i></i></p>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Qué formato prefiere?</strong> {{findArr('notesType', user.notes_type)}}
                  </div>
                  <div class="col-md-12 mt-3" v-if="user.notes_type === 'written'">
                    {{user.notes}}
                  </div>
                  <div class="col-12 mt-2" v-if="user.notes_type === 'spoken'">
                    <div class="row mt-3 row" v-if="notesAudio">
                      <audio :src="notesAudio" controls></audio>
                    </div>
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4">Antecedentes Personales Patológicos y Antecedentes Quirúrgicos</h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    <strong>¿Padece de alguna alergia?</strong> {{findArr('yesNo', user.allergy)}}
                  </div>
                  <div class="col pt-2" v-if="user.allergy === 'yes'">
                    <div class="p-md-4" v-if="user.allergies.length > 0">
                      <h5 class="mb-2 card-title text-muted">Alergías conocidas</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">Elemento/Alergeno</th>
                              <th scope="col" class="text-center">Severidad</th>
                              <th scope="col" class="text-center">Año de Inicio</th>
                              <th scope="col">Síntomas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.allergies">
                              <td>{{item.fields.element}}</td>
                              <td class="text-center">{{item.fields.severity}}</td>
                              <td class="text-center">{{item.fields.year}}</td>
                              <td>{{item.fields.symptoms}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Le han realizado una transfusión de sangre?</strong> {{findArr('yesNo', user.transfusion)}}
                  </div>
                  <div class="col-md-12" v-if="user.transfusion === 'yes'">
                    <strong>¿Cuándo le realizaron la transfusión?</strong> {{formatDate(user.transfusion_date, 'MMM YYYY')}}
                  </div>
                  <div class="col-md-12" v-if="user.transfusion === 'yes'">
                    <strong>¿Cuál fue la razón de la transfusión?</strong> {{user.transfusion_reason}}
                  </div>
                  <div class="col-md-12">
                    <strong>Le han realizado una cirugía en el pasado?</strong> {{findArr('yesNo', user.surgery)}}
                  </div>
                  <div class="col pt-2" v-if="user.surgery === 'yes'">
                    <div class="p-md-4" v-if="user.surgeries.length > 0">
                      <h5 class="mb-2 card-title text-muted">Cirugías previas</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col" class="text-center">Fecha</th>
                              <th scope="col" class="text-center">¿Anestesia General?</th>
                              <th scope="col">Razón</th>
                              <th scope="col">Médico</th>
                              <th scope="col">Institución</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.surgeries">
                              <td>{{item.fields.name}}</td>
                              <td class="text-center">{{formatDate(item.fields.date, 'MMM YYYY')}}</td>
                              <td class="text-center">{{findArr('yesNo', item.fields.general_anesthesia)}}</td>
                              <td>{{item.fields.reason}}</td>
                              <td>{{item.fields.doctor}}</td>
                              <td>{{item.fields.institution}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <strong>¿Alguna condición o característica de su cuerpo (físico) que sea importante mencionar y tomar en cuenta?</strong> {{user.body}}
                  </div>
                  <div class="col-md-12">
                    <strong>¿Padece o ha padecido alguna otra enfermedad?</strong> {{findArr('yesNo', user.disease)}}
                  </div>
                  <div class="col pt-2" v-if="user.disease === 'yes'">
                    <div class="p-md-4" v-if="user.diseases.length > 0">
                      <h5 class="mb-2 card-title text-muted">Enfermedades previas/actuales</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col" class="text-center">Mes y año de diagnóstico</th>
                              <th scope="col" class="text-center">¿Puede considerarse resuelta?</th>
                              <th scope="col">Información adicional</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.diseases">
                              <td>{{item.fields.name==='other'?item.fields.name_other:item.fields.name}}</td>
                              <td class="text-center">{{formatDate(item.fields.date, 'MMM YYYY')}}</td>
                              <td class="text-center">{{findArr('yesNo', item.fields.resolved)}}</td>
                              <td>{{item.fields.notes}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="attachments.others.length > 0 && user.disease === 'yes'">
                    <div class="p-md-4">
                      <h5 class="mb-2 card-title text-muted">Archivos adjuntos relacionados a otras enfermedades</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Adjunto</th>
                              <th scope="col" class="text-center">Tamaño</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in attachments.others">
                              <td><paperclip-icon class="fea icon-sm mr-2"></paperclip-icon></td>
                              <td><a :href="item.Url" target="_blank"><i class="mdi mdi-open-in-new"> </i> &nbsp;{{item.Filename}} </a></td>
                              <td class="text-center"><small class="text-dark mx-2 text-muted">{{humanFileSize(item.Size)}}</small></td>
                              <!--td>
                                <a href="#" class="" v-b-modal.DeleteObjectModal @click.prevent="fileNameToDelete = item.Filename; folderToDelete = item.Folder">
                                  <x-circle-icon class="fea icon-sm text-danger"></x-circle-icon>
                                </a>
                              </td-->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4" v-if="user.patient_sex === 'female'">Antecedente Ginecobstétrico</h4>
                <hr v-if="user.patient_sex === 'female'"/>
                <div class="row" v-if="user.patient_sex === 'female'">
                  <div class="col-md-12">
                    <strong>Edad de primera menstruación:</strong> {{findArr('menstruationAge', user.f_menstruation_age)}}
                  </div>
                  <div class="col-md-12" v-if="user.f_menstruation_age && user.f_menstruation_age !== 'na'">
                    <strong>Inicio de Vida Sexual:</strong> {{findArr('ivsaAge', user.ivsa_age)}}
                  </div>
                  <div class="col-md-12" v-if="user.f_menstruation_age && user.f_menstruation_age !== 'na'">
                    <strong> Mes y año de última menstruación:</strong> {{formatDate(user.l_menstruation_age, 'MMM YYYY')}}
                  </div>
                  <div class="col-md-12" v-if="user.ivsa_age && user.ivsa_age !== 'na'">
                    <strong>¿Está embarazada?</strong> {{findArr('yesNo', user.pregnant)}}
                  </div>
                  <div class="col-md-12" v-if="user.ivsa_age && user.ivsa_age !== 'na'">
                    <strong>¿Ha tenido embarazos previos?</strong> {{findArr('yesNo', user.previous_pregnancies)}}
                  </div>
                  <div class="col-md-12" v-if="user.previous_pregnancies === 'yes'">
                    <strong>¿Cuántos embarazos previos ha tenido?</strong> {{user.previous_pregnancies_qty}}
                  </div>
                  <div class="col-md-12" v-if="user.previous_pregnancies === 'yes'">
                    <strong>¿Cuántos partos ha tenido?</strong> {{user.birth_qty}}
                  </div>
                  <div class="col-md-12" v-if="user.previous_pregnancies === 'yes'">
                    <strong>¿Cuántas cesáreas ha tenido?</strong> {{user.previous_pregnancies_qty}}
                  </div>
                  <div class="col-md-12" v-if="user.previous_pregnancies === 'yes'">
                    <strong>¿Cuántos abortos ha tenido?</strong> {{user.abortion_qty}}
                  </div>
                  <div class="col-md-12" v-if="user.previous_pregnancies === 'yes'">
                    <strong>¿Cuántos meses ha lactado en total?</strong> {{user.lactation_months}}
                  </div>
                </div>
                <h4 class="card-title mt-5 mb-4">Antecedentes Personales No Patológicos</h4>
                <hr/>
                <div class="row">
                  <div class="col-md-12">
                    <strong>¿Fuma / Fumó?</strong> {{findArr('yesNo', user.smoke)}}
                  </div>

                  <div class="col-md-12" v-if="user.smoke === 'yes'">
                    <strong>¿Cuántos años fumó / ha fumado?</strong> {{user.smoke_years}}
                  </div>
                  <div class="col-md-12" v-if="user.smoke === 'yes'">
                    <strong>¿Cuántos cigarros al día?</strong> {{user.smoke_qty_day}}
                  </div>
                  <div class="col-md-12">
                    <strong>¿Bebe alcohol?</strong> {{findArr('yesNo', user.alcohol)}}
                  </div>
                  <div class="col-md-12" v-if="user.alcohol === 'yes'">
                    <strong>¿Con qué frecuencia?</strong> {{user.alcohol_frequency}}
                  </div>
                  <div class="col-md-12" v-if="user.alcohol === 'yes'">
                    <strong>¿Qué cantidad (copas) en esa frecuencia?</strong> {{user.alcohol_amount}}
                  </div>
                  <div class="col-md-12" v-if="user.alcohol === 'yes'">
                    <strong>¿Cuántos años ha bebido así?</strong> {{user.alcohol_years}}
                  </div>
                  
                  <!-- Drugs-->
                  <div class="col-md-12">
                    <strong>¿Consume o ha consumido drogas?</strong> {{findArr('yesNo', user.drugs)}}
                  </div>
                  <div class="col-md-12" v-if="user.drugs === 'yes'">
                    <strong>¿Cuál/cuáles?</strong> {{user.drugs_description}}
                  </div>
                  <div class="col-md-12" v-if="user.drugs === 'yes'">
                    <strong>¿Con qué frecuencia?</strong> {{user.drugs_frequency}}
                  </div>
                  <div class="col-md-12" v-if="user.drugs === 'yes'">
                    <strong>¿Cuántos años consumió / ha consumido?</strong> {{user.drugs_years}}
                  </div>
                  <div class="col-md-12">
                    <strong>¿Realiza ejercicio con frecuencia?</strong> {{findArr('yesNo', user.exercise)}}
                  </div>
                  <!-- Eating -->
                  <div class="col-md-12">
                    <strong>¿Qué describe mejor su dieta típica?</strong> {{user.eating_habit}}
                  </div>

                  <!-- Direct Relatives -->
                  <div class="col-md-12">
                    <strong>¿Tiene familiares que hayan padecido cáncer, algún tumor o alguna enfermedad catastrófica?</strong> {{findArr('yesNo', user.direct_relative)}}
                  </div>
                  <div class="col-md-12" v-if="user.direct_relative === 'yes'">
                    <div class="p-md-4" v-if="user.direct_relatives.length > 0">
                      <h5 class="mb-2 card-title text-muted">Familiares directos</h5>
                      <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                          <thead>
                            <tr>
                              <th scope="col">¿Quién?</th>
                              <th scope="col">Enfermedad/Padecimiento</th>
                              <th scope="col" class="text-center">¿Está vivo?</th>
                              <th scope="col" class="text-center">¿Edad de Diagnóstico?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in user.direct_relatives">
                              <td>{{findArr('relatives', item.fields.relative)}}</td>
                              <td>{{item.fields.disease}}</td>
                              <td class="text-center">{{findArr('yesNo', item.fields.alive)}}</td>
                              <td class="text-center">{{item.fields.diagnosis_age}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Footer />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<style>
</style>
